import React from 'react';

import DataLoader from '../components/DataLoader';
import Search from '../components/Search';
import Toolbar from '../components/Toolbar';
import TrackingClientList from '../components/TrackingClientList';
import * as queries from '../graphql/queries';

const TrackingClients = props => (
  <DataLoader query={queries.listTrackingClients} dataKey="listTrackingClients">
    {({ data, refresh }) => (
      <Search data={data} keys={['name', 'slug']}>
        {({ data: clients, getInputProps }) => (
          <>
            <Toolbar
              inputProps={getInputProps}
              createButtonText="Create Client"
              createPath="/tracking-clients/create"
              onRefresh={() => refresh()}
              searchInputPlaceHolder="Search for clients..."
            />
            <TrackingClientList clients={clients} />
          </>
        )}
      </Search>
    )}
  </DataLoader>
);

export default TrackingClients;
