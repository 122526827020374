import React from 'react';
import styled from 'styled-components';
import FadeLoader from 'react-spinners/FadeLoader';

const Loading = styled.div`
  display: flex;
  justify-content: center;
`;

export default ({ loading }) => (
  <Loading>
    <FadeLoader sizeUnit={'px'} size={512} color={'orange'} loading={loading} />
  </Loading>
);
