import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default class extends React.Component {
  state = { dropdownOpen: false };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  onAction = type => {
    const { aclKey, groupName } = this.props;

    this.props.onAction({
      type,
      payload: { aclKey, groupName }
    });
  };

  onAdd = this.onAction.bind(this, 'GROUP_ADD');
  onRemove = this.onAction.bind(this, 'GROUP_REMOVE');
  onRename = this.onAction.bind(this, 'GROUP_RENAME');

  render() {
    return (
      <ButtonDropdown className="float-right" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret size="sm" />
        <DropdownMenu>
          <DropdownItem onClick={this.onAdd}>Add group</DropdownItem>
          <DropdownItem onClick={this.onRename}>Rename group</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={this.onRemove}>Remove group</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}
