import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavItem, TabPane, Row, Col, Table, Input, FormGroup, Label } from 'reactstrap';

import ACL from './ACL';
import AliasCarrier from './AliasCarrier';
import ShowDeliveryDelayColumn from './ShowDeliveryDelayColumn';
import EnableExport from './EnableExport';
import ExportInterval from './ExportInterval';
import EnableCharts from './EnableCharts';
import SecurityGroupActions from './SecurityGroupActions';
import { Nav, NavLink, Tabs, TabContent, Tbody, Th } from './styles';

const noop = () => { };

export default class SecurityGroups extends React.Component {
  static propTypes = {
    groups: PropTypes.arrayOf(PropTypes.shape({})),
    onAction: PropTypes.func,
    onAliasCarrierUpdate: PropTypes.func,
    onCustomergroupLabelUpdate: PropTypes.func,
    onShowDeliveryDelayColumnUpdate: PropTypes.func,
    onEnableExportUpdate: PropTypes.func,
    onExportIntervalUpdate: PropTypes.func,
    onEnableChartsUpdate: PropTypes.func,
  };
  static defaultProps = {
    groups: [],
    onAction: noop,
    onAliasCarrierUpdate: noop,
    onCustomergroupLabelUpdate: noop,
    onShowDeliveryDelayColumnUpdate: noop,
    onEnableExportUpdate: noop,
    onExportIntervalUpdate: noop,
    onEnableChartsUpdate: noop,
  };

  state = {
    groups: []
  };

  constructor(props) {
    super(props);

    const [group = {}] = props.groups;

    this.state = {
      count: props.groups.length,
      activeTab: group.aclKey
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.count !== nextProps.groups.length) {
      const [group = {}] = nextProps.groups;
      return {
        count: nextProps.groups.length,
        activeTab: group.aclKey
      };
    }

    return null;
  }

  toggleTab = tab => {
    this.setState({
      activeTab: tab
    });
  };

  render() {
    const { activeTab } = this.state;
    const { groups, onAction } = this.props;

    return (
      <Tabs>
        <Nav vertical>
          {groups.map(({ groupName, aclKey, relations = [] } = {}) => (
            <NavItem key={aclKey}>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === aclKey })}
                onClick={() => {
                  this.toggleTab(aclKey);
                }}
              >
                {groupName || aclKey}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab} style={{ flex: 1 }}>
          {groups.map(({ groupName, aclKey, aliasCarrier, customergroupLabel, showDeliveryDelayColumn, enableExport, exportInterval, enableCharts, relations = [] }) => (
            <TabPane key={aclKey} tabId={aclKey}>
              <Row>
                <Col sm="12">
                  <SecurityGroupActions
                    aclKey={aclKey}
                    groupName={groupName}
                    onAction={onAction}
                  />

                  <AliasCarrier
                    aliasCarrier={aliasCarrier}
                    onAliasCarrierUpdate={this.props.onAliasCarrierUpdate.bind(
                      null,
                      aclKey
                    )}
                    aclKey={aclKey}
                  />
                  <FormGroup row key={"customergroupLabel-" + aclKey}>
                    <Label for={"customergroupLabel-" + aclKey} sm={4}>
                      Customer Group Label
                    </Label>
                    <Col sm={8}>
                      <Input
                        id={"customergroupLabel-" + aclKey}
                        name={"customergroupLabel-" + aclKey}
                        type="text"
                        value={customergroupLabel || ''}
                        onChange={this.props.onCustomergroupLabelUpdate.bind(
                          null,
                          aclKey
                        )}
                      />
                    </Col>
                  </FormGroup>
                  <ShowDeliveryDelayColumn
                    showDeliveryDelayColumn={showDeliveryDelayColumn}
                    onShowDeliveryDelayColumnUpdate={this.props.onShowDeliveryDelayColumnUpdate.bind(
                      null,
                      aclKey
                    )}
                    aclKey={aclKey}
                  />
                  <EnableExport
                    enableExport={enableExport}
                    onEnableExportUpdate={this.props.onEnableExportUpdate.bind(
                      null,
                      aclKey
                    )}
                    aclKey={aclKey}
                  />
                  <ExportInterval
                    exportInterval={exportInterval}
                    onExportIntervalUpdate={this.props.onExportIntervalUpdate.bind(
                      null,
                      aclKey
                    )}
                    aclKey={aclKey}
                    enableExport={enableExport}
                  />
                  <EnableCharts
                    enableCharts={enableCharts || undefined}
                    onEnableChartsUpdate={this.props.onEnableChartsUpdate.bind(
                      null,
                      aclKey
                    )}
                    aclKey={aclKey}
                  />

                  <Table borderless>
                    <thead>
                      <tr>
                        <Th style={{ width: '23%' }}>Type</Th>
                        <Th style={{ width: '23%' }}>FAST-DB</Th>
                        <Th style={{ width: '23%' }}>Number</Th>
                        <Th style={{ width: '23%' }}>Description</Th>
                        <Th style={{ width: '8%' }} />
                      </tr>
                    </thead>
                    <Tbody>
                      {relations.map((acl, index) => (
                        <ACL
                          key={`${acl.num}-${acl.numType}`}
                          {...acl}
                          groupName={groupName || aclKey}
                          onAction={onAction}
                        />
                      ))}
                      <ACL
                        newAcl={true}
                        groupName={groupName || aclKey}
                        onAction={onAction}
                      />
                    </Tbody>
                  </Table>
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </Tabs>
    );
  }
}
