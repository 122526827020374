import React from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';

const noop = () => {};

const SecurityGroup = ({ value = '', label = 'Submit', onSubmit = noop, onCancel = noop }) => {
  const inputRef = React.createRef();

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(inputRef.current.value);
  }

  return (
    <Form inline onSubmit={handleSubmit} style={{ marginBottom: 30 }}>
      <FormGroup>
        <Input
          innerRef={inputRef}
          style={{ width: 300, marginRight: 10 }}
          name="groupName"
          defaultValue={value}
          type="text"
          placeholder="Group name"
        />
      </FormGroup>
      <Button outline color="primary" style={{ marginRight: 10 }}>
        {label}
      </Button>
      <Button outline color="secondary" type="button" onClick={onCancel}>
        Cancel
      </Button>
    </Form>
  );
};

export default SecurityGroup;
