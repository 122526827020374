import uuidv4 from 'uuid/v4';

const nullIfUndefined = obj => obj === undefined ? null : obj;

const filterAcl = ({ relations, acl }) =>
  relations.filter(rel => !(nullIfUndefined(rel.numType) === nullIfUndefined(acl.numType) && nullIfUndefined(rel.num) === nullIfUndefined(acl.num) && nullIfUndefined(rel.fastDb) === nullIfUndefined(acl.fastDb)));

export default (state = {}, action) => {
  switch (action.type) {
    case 'GROUP_ADD': {
      return { ...state, securityGroup: {} };
    }
    case 'GROUP_RENAME': {
      const { aclKey, groupName } = action.payload;
      return { ...state, securityGroup: { aclKey, groupName } };
    }
    case 'GROUP_REMOVE': {
      const { aclKey } = action.payload;
      const remove = state.groups.find(group => group.aclKey === aclKey);
      const groups = state.groups.filter(group => !(group.aclKey === aclKey));
      const removed = state.removedGroups ? [...state.removed, remove] : [remove];
      return { ...state, groups, removed };
    }
    case 'GROUP_CANCEL': {
      return { ...state, securityGroup: undefined };
    }
    case 'GROUP_ACTION_RENAME': {
      const { aclKey, groupName } = action.payload;
      const groups = state.groups.map(
        group => (group.aclKey === aclKey ? { ...group, groupName } : group)
      );
      return { ...state, groups, securityGroup: undefined };
    }
    case 'GROUP_ACTION_CREATE': {
      const { groupName } = action.payload;
      const groups = [...state.groups, { groupName, aclKey: uuidv4(), relations: [] }];
      return { ...state, groups, securityGroup: undefined };
    }
    case 'ACL_ADD': {
      const { groupName, ...acl } = action.payload;
      const groups = state.groups.map(
        group =>
          group.groupName === groupName ? { ...group, relations: [...group.relations, acl] } : group
      );
      return { ...state, groups };
    }
    case 'ACL_REMOVE': {
      const { groupName, ...acl } = action.payload;
      const groups = state.groups.map(
        group =>
          group.groupName === groupName
            ? { ...group, relations: filterAcl({ relations: group.relations, acl }) }
            : group
      );
      return { ...state, groups };
    }
    default:
      return state;
  }
};
