import format from 'date-fns/format';

export const formatDate = (date) => format(date, 'MMM D, YYYY h:mm:ss A');

export const formatLastSeen = (date, includeTime = true) => {
  if (!date) return null;
  return includeTime
    ? format(date, 'DD.MM.YYYY HH:mm:ss')
    : format(date, 'DD.MM.YYYY');
};

export function debounce(fn, time) {
  let timeoutId;
  return wrapper;
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}

export const attributesReducer = (user) =>
  user.Attributes.reduce(
    (attributes, attr) => ({
      ...attributes,
      [attr.Name]: attr.Value,
    }),
    {}
  );
