import React from 'react';
import { Container as BsContainer, Row, Col, Card as BsCard } from 'reactstrap';
import styled from 'styled-components';

const Container = styled(BsContainer)`
  padding-top: 30px;
`;

const Card = styled(BsCard)`
  padding: 20px;
  margin-bottom: 20px;
`;

export default ({ children }) => (
  <Container>
    <Row>
      <Col>
        <Card>{children}</Card>
      </Col>
    </Row>
  </Container>
);
