import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import matchSorter from 'match-sorter';

import { debounce } from '../utils';

export default class Search extends React.Component {
  state = { data: this.props.data };

  static propTypes = {
    keys: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.shape({}))
  };

  static defaultProps = {
    keys: ['name'],
    data: []
  };

  search = debounce(inputValue => {
    this.setState({ inputValue }, this.updateList);
  }, 500);

  updateList = () => {
    const { inputValue } = this.state;
    const { keys, data } = this.props;

    this.setState({
      data: inputValue !== '' ? matchSorter(data, inputValue, { keys }) : data
    });
  };

  render() {
    const { children } = this.props;
    const { data } = this.state;

    return (
      <Downshift>
        {({ getInputProps }) => (
          <div>
            {children &&
              children({
                data,
                getInputProps: getInputProps({
                  onChange: event => this.search(event.target.value)
                })
              })}
          </div>
        )}
      </Downshift>
    );
  }
}
