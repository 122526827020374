import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { formatLastSeen } from '../utils';

import { Tbody, Tr, Td } from './styles';

export const UserLink = ({ username }) => (
  <Link to={`/users/${username}`}>{username}</Link>
);

const getUserAttribute = (attributes = [], name) => {
  const attr = attributes.find((attr) => attr.Name === name) || {};
  return attr.Value;
};

export default ({ users }) => (
  <Table borderless size="sm">
    <thead>
      <tr>
        <th>Username</th>
        <th>E-Mail</th>
        <th>Group</th>
        <th>Status</th>
        <th>Enabled</th>
        <th>LastSeen</th>
      </tr>
    </thead>
    <Tbody>
      {users.map((user) => (
        <Tr key={user.Username}>
          <Td>
            <UserLink username={user.Username} />
          </Td>
          <Td>{getUserAttribute(user.Attributes, 'email')}</Td>
          <Td>{getUserAttribute(user.Attributes, 'custom:userCategory')}</Td>
          <Td>{user.UserStatus}</Td>
          <Td>{user.Enabled ? 'Enabled' : 'Disabled'}</Td>
          <Td>{formatLastSeen(user.LastSeen, false)}</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);
