import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';

import CustomerName from './CustomerName';
import SecurityGroups from './SecurityGroups';
import SecurityGroup from './SecurityGroup';

import defaultReducer from './reducer';

const noop = () => { };

const BlankSlate = ({
  message = 'There are no security groups yet!',
  label = 'Create',
  onClick = noop
}) => (
  <Alert color="light">
    {message}
    <Button color="link" onClick={onClick}>
      {label}
    </Button>
  </Alert>
);

const StatusAlert = ({ message = 'Configuration has changed!', label = 'Save', onSave = noop }) => (
  <Alert color="warning">
    {message}
    <Button color="link" onClick={onSave}>
      Save
    </Button>
  </Alert>
);

export default class SecurityGroupsEditor extends React.Component {
  static propTypes = {
    groups: PropTypes.arrayOf(PropTypes.shape({})),
    stateReducer: PropTypes.func,
    customerId: PropTypes.string,
    timestamp: PropTypes.number
  };
  static defaultProps = {
    groups: [],
    stateReducer: defaultReducer,
    customerId: undefined,
    timestamp: new Date().getTime()
  };

  state = {
    groups: this.props.groups,
    customerId: this.props.customerId,
    customerName: this.props.customerName,
    timestamp: this.props.timestamp
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.customerId !== nextProps.customerId ||
      prevState.timestamp !== nextProps.timestamp
    ) {
      return {
        customerId: nextProps.customerId,
        customerName: nextProps.customerName,
        groups: nextProps.groups,
        timestamp: nextProps.timestamp
      };
    }

    return null;
  }

  onAction = action => {
    // console.log('onAction', action);
    this.setState(
      prevState => this.props.stateReducer(prevState, action)
      // () => console.log(this.state)
    );
  };

  updateCustomerName = e => {
    const { value } = e.target;
    this.setState({
      customerName: value
    });
  };

  updateAliasCarrier = (aclKey, e) => {
    const groups = JSON.parse(JSON.stringify(this.state.groups));
    const group = groups.find(group => group.aclKey === aclKey)
    group.aliasCarrier = !group.aliasCarrier
    this.setState({
      groups
    });
  };
  onCustomergroupLabelUpdate = (aclKey, e) => {
    const groups = JSON.parse(JSON.stringify(this.state.groups));
    const group = groups.find(group => group.aclKey === aclKey)
    group.customergroupLabel = e.target.value
    this.setState({
      groups
    });
  };
  updateShowDeliveryDelayColumn = (aclKey, e) => {
    const groups = JSON.parse(JSON.stringify(this.state.groups));
    const group = groups.find(group => group.aclKey === aclKey)
    group.showDeliveryDelayColumn = !group.showDeliveryDelayColumn
    this.setState({
      groups
    });
  };
  updateEnableExport = (aclKey, e) => {
    const groups = JSON.parse(JSON.stringify(this.state.groups));
    const group = groups.find(group => group.aclKey === aclKey)
    group.enableExport = !group.enableExport
    this.setState({
      groups
    });
  };
  updateExportInterval = (aclKey, e) => {
    const groups = JSON.parse(JSON.stringify(this.state.groups));
    const group = groups.find(group => group.aclKey === aclKey)
    group.exportInterval = e.value
    this.setState({
      groups
    });
  };
  updateEnableCharts = (aclKey, e, chartname) => {
    const groups = JSON.parse(JSON.stringify(this.state.groups));
    const group = groups.find(group => group.aclKey === aclKey)
    if (!group.enableCharts) group.enableCharts = {}
    if (group.enableCharts[chartname] === undefined || group.enableCharts[chartname] === null) group.enableCharts[chartname] = true;
    group.enableCharts[chartname] = !group.enableCharts[chartname]
    this.setState({
      groups
    });
  };

  isDirty(state = this.state, props = this.props) {
    const compare = ['customerId', 'customerName', 'groups'];
    const prev = pick(props, compare);
    const curr = pick(state, compare);
    // console.log({ prev, curr });
    const blank = props.groups.length === 0 && state.groups.length === 0;
    return !blank && !isEqual(prev, curr);
  }

  save = () => {
    const { customerId, customerName, groups, removed } = this.state;
    this.props.onSave({ customerId, customerName, groups, removed });
  };

  render() {
    const { groups, securityGroup, customerName } = this.state;
    const showSG = !!securityGroup;
    const showBlankSlate = !groups.length && !showSG;
    const isSgRename = securityGroup && !!securityGroup.aclKey;
    const sgValue = securityGroup && securityGroup.groupName;
    const sgLabel = isSgRename ? 'Rename' : 'Create';
    const sgAction = value =>
      this.onAction({
        type: isSgRename ? 'GROUP_ACTION_RENAME' : 'GROUP_ACTION_CREATE',
        payload: { aclKey: securityGroup.aclKey, groupName: value }
      });

    const sgCancel = () => this.onAction({ type: 'GROUP_CANCEL' });

    return (
      <>
        <CustomerName value={customerName} onChange={this.updateCustomerName} />
        {showBlankSlate && <BlankSlate onClick={() => this.onAction({ type: 'GROUP_ADD' })} />}
        {showSG && (
          <SecurityGroup label={sgLabel} value={sgValue} onSubmit={sgAction} onCancel={sgCancel} />
        )}
        {!showSG && <SecurityGroups groups={groups} onAction={this.onAction} onAliasCarrierUpdate={this.updateAliasCarrier} onCustomergroupLabelUpdate={this.onCustomergroupLabelUpdate} onShowDeliveryDelayColumnUpdate={this.updateShowDeliveryDelayColumn} onEnableExportUpdate={this.updateEnableExport} onExportIntervalUpdate={this.updateExportInterval} onEnableChartsUpdate={this.updateEnableCharts} />}
        {this.isDirty() && <StatusAlert onSave={this.save} />}
      </>
    );
  }
}
