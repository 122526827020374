import styled, { css } from 'styled-components';
import { Nav as BsNav, NavLink as BsNavLink, TabContent as BsTabContent } from 'reactstrap';

export const Nav = styled(BsNav)`
  margin: 5px 25px 10px 0;
  padding-right: 20px;
  border-right: 1px solid #eeeeee;
`;

export const NavLink = styled(BsNavLink)`
  padding-left: 0;

  &.active {
    text-decoration: underline;
  }
`;

export const Tabs = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const TabContent = styled(BsTabContent)`
  padding-left: 20px;
`;

export const Tbody = styled.tbody``;

export const Th = styled.th`
  width: 30%;
`;

export const Tr = styled.tr`
  & > td {
    border-top: 1px solid #ccc;
  }
  & > td:last-child {
    border: none;
    display: none;
  }
  &:hover > td:last-child {
    border: none;
    display: table-cell;
  }
`;

export const Td = styled.td``;

export const Tools = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props =>
    props.block &&
    css`
      height: 39px;
    `} width: 45px;
`;
