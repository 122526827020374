import { UserFragment, TrackingClientFragment } from './fragements';

export const getUser = `
  query getUser($username: String!) {
    getUser(username: $username) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const listUsers = `
  query  {
    listUsers {
      Username
      Attributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      UserStatus
      Enabled
      LastSeen
    }
  }
`;

export const listCustomers = `
  query {
    listCustomers {
      items {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const listSecurityGroups = `
  query listSecurityGroups($customerId: String!) {
    getCustomer(id: $customerId) {
      id
      name
    }
    listSecurityGroups(customerId: $customerId) {
      customerId
      customerName
      aclKey
      groupName
      aliasCarrier
      customergroupLabel
      showDeliveryDelayColumn
      enableExport
      exportInterval
      enableCharts{
        chartTransportOverview
        chartInternationalTransports
        chartDeliveryTimeReportByCountryKurier
        chartNegativeReportByCountryKurier
        chartDeliveryTimeReportByCountryLkw
        chartNegativeReportByCountryLkw
        chartDeliveryTimeReportByCountrySeefracht
        chartNegativeReportByCountrySeefracht
        chartDeliveryTimeReportByCountryLuftfracht
        chartNegativeReportByCountryLuftfracht
        chartDeliveryTimeReportByCountryPaket
        chartNegativeReportByCountryPaket
        chartTransportsDevelopment
        chartDeliveriesByCountry
        chartStoragePerformanceToday
        chartStoragePerformanceSpreitenbach
        chartStoragePerformanceBasel
        chartStoragePerformanceSchaffhausen
        chartStoragePerformanceBuchs
      }
      relations {
        num
        numType
        fastDb
        desc
      }
    }
  }
`;

export const searchSecurityGroups = `
  query searchSecurityGroups($query: String!) {
    searchSecurityGroups(query: $query) {
      customerName
      aclKey
      groupName
    }
  }
`;

export const listTrackingClients = `
query listTrackingClients {
  listTrackingClients {
    ...TrackingClientFragment
  }
}
${TrackingClientFragment}
`;

export const getTrackingClient = `
query getTrackingClient($id: String!) {
  client: getTrackingClient(id: $id) {
    ...TrackingClientFragment
  }
}
${TrackingClientFragment}
`;

export const searchTrackingClients = `
  query searchTrackingClients($slug: String!) {
    searchTrackingClients(slug: $slug) {
      id
      slug
    }
  }
`;
