import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Input, Button, Col, Alert, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { S3Image } from 'aws-amplify-react';
import slugify from '@sindresorhus/slugify';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import Loading from './Loading';
import SearchSecurityGroup from './SearchSecurityGroup';

const ImageWrapper = styled.div`
  display: flex;
  min-height: ${props => props.height}px;

  & > div > div > img {
    height: ${props => props.height}px;
    max-width: 100%;
  }
`;

const noop = () => {};

class TrackingClientForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    onValidate: PropTypes.func,
    client: PropTypes.shape({}),
    isUpdate: PropTypes.bool
  };

  static defaultProps = {
    onSubmit: noop,
    onCancel: noop,
    onDelete: noop,
    onValidate: noop,
    client: {},
    isUpdate: false
  };

  state = { name: '', slug: '', acid: '', logoHeight: 50, ...this.props.client };

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  handleAcidChange = e => {
    this.setState({
      acid: e || {value: null}
    });
  };

  handleImageUpload = async e => {
    try {
      const {
        name,
        files: [file]
      } = e.target;
      const ext = file.name.split('.').pop();
      const timestamp = new Date().getTime();
      const filename = `${this.state.id}/${name}-${timestamp}.${ext}`;

      this.setState({ imageUpload: true });
      const { key } = await Storage.put(filename, file, { contentType: file.type });
      this.setState({ [name]: key, imageUpload: false });
    } catch (error) {
      console.log(error);
      this.setState({ imageUpload: false });
    }
  };

  clearImage = () => {
    this.setState({ image: null });
  };

  handleLogoHeight = logoHeight => {
    this.setState({
      logoHeight
    });
  };

  slugify = () => {
    this.setState({
      ...(!this.state.slug && { slug: slugify(this.state.name) })
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { onSubmit } = this.props;

    const valid = await this.validate();
    if (!valid) return;

    try {
      const { id, createdAt, updatedAt, errors, success, imageUpload, ...input } = this.state;
      if(input.acid && input.acid.value !== undefined) {
        input.acid = input.acid.value;
      }
      if(!input.acid || (Array.isArray(input.acid) && input.acid.length === 0)) {
        input.acid = null;
      }
      await onSubmit({ id, input });
      this.setState({ success: true }, this.setAlertTimer);
    } catch (error) {
      console.error(error);
      this.setState({ errors: ['unexpected'] });
    }
  };

  clearAlertState = () => {
    this.setState({ success: undefined }, () =>
      this.props.history.push(`/tracking-clients/${this.state.id}`)
    );
  };

  setAlertTimer = () => {
    this.alertTimer = setTimeout(this.clearAlertState, 2000);
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.onDelete(id);
  };

  validate = async () => {
    const { id, slug } = this.state;
    const remoteErrors = (await this.props.onValidate({ id, slug })) || [];
    const errors = [...remoteErrors, !this.state.logo && 'logo'].filter(Boolean);

    this.setState({
      errors: errors
    });

    return !errors.length;
  };

  render() {
    const { errors = [] } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        {this.state.success && <Alert color="success">Saved successfully!</Alert>}
        {errors.includes('unexpected') && (
          <Alert color="danger">
            <b>Some unexpected error occurred!</b> Please check your entries and try again.
          </Alert>
        )}
        <FormGroup row>
          <Label for="name" sm={2}>
            Name
          </Label>
          <Col sm={10}>
            <Input
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
              onBlur={this.slugify}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="slug" sm={2}>
            Slug
          </Label>
          <Col sm={4}>
            <Input
              name="slug"
              type="text"
              value={this.state.slug}
              onChange={this.handleChange}
              invalid={errors.includes('slug')}
            />
            <FormFeedback>Slug is already taken</FormFeedback>
          </Col>
          <Col sm={6} style={{ fontSize: 14 }}>
            <Alert color="info" style={{ padding: '8px 20px' }}>
              used as url query parameter, <i>e.g. /?client={this.state.slug}</i>
            </Alert>
          </Col>
        </FormGroup>
        <FormGroup row key="acid">
          <Label for="acid" sm={2}>
            ACL
          </Label>
          <Col sm={10}>
            <SearchSecurityGroup
              value={this.state.acid}
              onChange={this.handleAcidChange}
              id="acid"
              name="acid"
              isClearable={true}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="logo" sm={2}>
            Logo
          </Label>
          <Col sm={4}>
            <Input
              name="logo"
              type="file"
              accept="image/*"
              onChange={this.handleImageUpload}
              invalid={errors.includes('logo')}
            />
            <FormFeedback>Logo is required</FormFeedback>
          </Col>
          <Col sm={6}>
            <ImageWrapper height={this.state.logoHeight}>
              <S3Image imgKey={this.state.logo} />
            </ImageWrapper>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="logoHeight" sm={2}>
            Logo height
          </Label>
          <Col sm={4} style={{ paddingTop: 8 }}>
            <Slider
              min={20}
              max={60}
              defaultValue={this.state.logoHeight}
              onChange={this.handleLogoHeight}
            />
          </Col>
          <Col sm={4}>{this.state.logoHeight} px</Col>
        </FormGroup>
        <FormGroup row>
          <Label for="image" sm={2}>
            Background image
          </Label>
          <Col sm={4}>
            <Input name="image" type="file" accept="image/*" onChange={this.handleImageUpload} />
            <Button color="link" type="button" onClick={this.clearImage}>
              Clear
            </Button>
          </Col>
          <Col sm={6}>
            <ImageWrapper height={250}>
              {this.state.imageUpload ? (
                <Loading size={256} />
              ) : (
                this.state.image && <S3Image imgKey={this.state.image} />
              )}
            </ImageWrapper>
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Col sm={12}>
            <Button outline color="primary">
              {this.props.isUpdate ? 'Update' : 'Create'}
            </Button>
            <Button
              type="button"
              outline
              color="secondary"
              onClick={this.props.onCancel}
              style={{ marginLeft: 5 }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              outline
              color="danger"
              onClick={this.handleDelete}
              style={{ float: 'right' }}
            >
              Delete
            </Button>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

export default withRouter(TrackingClientForm);
