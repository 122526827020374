import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Box = styled.div`
  display: flex;

  & > div {
    flex: 1;
  }
`;

export const Refresh = styled(FontAwesomeIcon).attrs({
  icon: 'sync-alt',
  size: 'md'
})`
  color: #007bff;
`;
