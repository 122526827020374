import 'babel-polyfill';
import 'unfetch/polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';

import App from './App';
import config from './config';

Amplify.configure(config);

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
