export const UserFragment = `
  fragment UserFragment on User {
    Username
    UserAttributes {
      Name
      Value
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    LastSeen
  }
`;

export const TrackingClientFragment = `
  fragment TrackingClientFragment on TrackingClient {
    id
    name
    slug
    acid
    logo
    logoHeight
    image
    createdAt
    updatedAt
  }
`;
