import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Button, FormGroup, Input } from 'reactstrap';
import Select from 'react-select';
import matchSorter from 'match-sorter';
import Downshift from 'downshift';
import format from 'date-fns/format';

import { debounce, attributesReducer } from '../utils';
import Loading from '../components/Loading';
import UserList from '../components/UserList';
import * as queries from '../graphql/queries';
import { Box } from './styles';

const attributeOptions = [
  { value: 'Username', label: 'Username' },
  { value: 'attr.email', label: 'Email' },
  { value: 'attr.name', label: 'Name' },
  { value: 'attr.custom:userCategory', label: 'Group' },
  { value: 'Enabled', label: 'Enabled' },
  { value: 'UserStatus', label: 'Status' },
];

export default class extends React.Component {
  state = {
    keys: ['Username'],
  };

  componentDidMount() {
    this.getData();
  }

  async getData(props = this.props) {
    this.setState({
      loading: true,
    });

    try {
      const { data: { listUsers } = {} } = await API.graphql(
        graphqlOperation(queries.listUsers)
      );

      const users = listUsers.map((user) => ({
        ...user,
        attr: attributesReducer(user),
      }));

      this.setState({ users, list: users, loading: false });
    } catch ({ data, errors }) {
      this.setState({ loading: false });
    }
  }

  search = debounce((inputValue) => {
    this.setState(
      {
        inputValue,
      },
      this.updateList
    );
  }, 500);

  updateList = () => {
    const { users = [], keys, inputValue } = this.state;

    let list = inputValue
      ? matchSorter(users, inputValue, {
          keys,
        })
      : users;

    this.setState({ list });
  };

  selectAttribute = (option) => {
    this.setState({
      keys: [option.value],
    });
  };

  onStateChange = ({ isOpen }) => {
    if (!isOpen) {
      this.setState(
        {
          inputValue: '',
        },
        this.updateList
      );
    }
  };

  exportUsers = () => {
    const { list = [] } = this.state;
    const data = [
      `Username,Email,Group,Status,Enabled,LastSeen`,
      ...list.map((user) => {
        const category = user.attr['custom:userCategory'];
        return [
          user.Username,
          user.attr.email,
          category ? category : '',
          user.UserStatus,
          user.Enabled ? 'Enabled' : 'Disabled',
          user.LastSeen ? user.LastSeen : '',
        ].join(',');
      }),
    ];
    const url = window.URL.createObjectURL(
      new Blob([data.join('\n')], { type: 'text/csv' })
    );
    const link = document.createElement('a');
    link.href = url;
    const fileName = `${format(new Date(), 'YYYYMMDD')}_dashboard-users.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  render() {
    const { list = [], loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <Downshift onStateChange={this.onStateChange}>
          {({ getInputProps }) => {
            return (
              <div>
                <Box>
                  <div>
                    <Button
                      outline
                      color="primary"
                      onClick={() => this.props.history.push('/users/create')}
                    >
                      Create User
                    </Button>
                    <Button
                      outline
                      color="primary"
                      style={{ marginLeft: 5 }}
                      onClick={() => this.exportUsers()}
                    >
                      Export Users
                    </Button>
                    <Button
                      outline
                      color="secondary"
                      style={{ marginLeft: 5 }}
                      onClick={() => this.getData(false)}
                    >
                      Refresh
                    </Button>
                  </div>
                  <Select
                    onChange={this.selectAttribute}
                    defaultValue={attributeOptions[0]}
                    options={attributeOptions}
                  />
                  <FormGroup style={{ marginLeft: 5 }}>
                    <Input
                      {...getInputProps({
                        onChange: (event) => {
                          const value = event.target.value;
                          this.search(value);
                        },
                      })}
                      placeholder="Search for value..."
                    />
                  </FormGroup>
                </Box>
                <UserList users={list} />
              </div>
            );
          }}
        </Downshift>
      </div>
    );
  }
}
