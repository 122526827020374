import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Col, Input } from 'reactstrap';

const noop = () => { };

export default class ShowDeliveryDelayColumn extends React.Component {
  static propTypes = {
    showDeliveryDelayColumn: PropTypes.bool,
    onShowDeliveryDelayColumnUpdate: PropTypes.func,
    aclKey: PropTypes.string.isRequired
  };
  static defaultProps = {
    showDeliveryDelayColumn: false,
    onShowDeliveryDelayColumnUpdate: noop,
    aclKey: ''
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { showDeliveryDelayColumn } = this.props;
    return (
      <FormGroup row key={"showDeliveryDelayColumn-" + this.props.aclKey} style={{ 'clear': 'both' }}>
        <Label for={"showDeliveryDelayColumn-" + this.props.aclKey} sm={4}>
          Show Delivery Delay Column
        </Label>
        <Col sm={8}>
          <Input
            type="checkbox"
            id={"showDeliveryDelayColumn-" + this.props.aclKey}
            name={"showDeliveryDelayColumn-" + this.props.aclKey}
            onChange={this.props.onShowDeliveryDelayColumnUpdate}
            checked={showDeliveryDelayColumn}
            style={{ 'margin-left': '0' }}
          />
        </Col>
      </FormGroup>
    );
  }
}
