import { UserFragment, TrackingClientFragment } from './fragements';

export const createUser = `
  mutation createUser($username: String, $attributes: [UserAttributeInput]) {
    data: createUser(username: $username, attributes: $attributes) {
      user {
        ...UserFragment
      }
      error {
        code
        message
      }
    }
  }
  ${UserFragment}
`;

export const updateUser = `
  mutation updateUser($username: String, $attributes: [UserAttributeInput]) {
    data: updateUser(username: $username, attributes: $attributes) {
      user {
        ...UserFragment
      }
      error {
        code
        message
      }
    }
  }
  ${UserFragment}
`;

export const disableUser = `
  mutation disableUser($username: String) {
    user: disableUser(username: $username) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const enableUser = `
  mutation enableUser($username: String) {
    user: enableUser(username: $username) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const deleteUser = `
  mutation deleteUser($username: String) {
    deleteUser(username: $username)
  }
`;

export const updateSecurityGroups = `
  mutation updateSecurityGroups($groups: [SecurityGroupInput]) {
    saveSecurityGroups(groups: $groups) {
      customerId
      aclKey
      customerName
      groupName
      aliasCarrier
      customergroupLabel
      showDeliveryDelayColumn
      enableExport
      exportInterval
      enableCharts {
        chartTransportOverview
        chartInternationalTransports
        chartDeliveryTimeReportByCountryKurier
        chartNegativeReportByCountryKurier
        chartDeliveryTimeReportByCountryLkw
        chartNegativeReportByCountryLkw
        chartDeliveryTimeReportByCountrySeefracht
        chartNegativeReportByCountrySeefracht
        chartDeliveryTimeReportByCountryLuftfracht
        chartNegativeReportByCountryLuftfracht
        chartDeliveryTimeReportByCountryPaket
        chartNegativeReportByCountryPaket
        chartTransportsDevelopment
        chartDeliveriesByCountry
        chartStoragePerformanceToday
        chartStoragePerformanceSpreitenbach
        chartStoragePerformanceBasel
        chartStoragePerformanceSchaffhausen
        chartStoragePerformanceBuchs
      }
      relations {
        num
        numType
        fastDb
        desc
      }
    }
  }
`;

export const deleteSecurityGroups = `
  mutation deleteSecurityGroups($groups: [SecurityGroupInput]) {
    deleteSecurityGroups(groups: $groups) {
      customerId
      aclKey
    }
  }
`;

export const createTrackingClient = `
  mutation createTrackingClient($id: String!, $input: TrackingClientInput!) {
    createTrackingClient(id: $id, input: $input) {
      ...TrackingClientFragment
    }
  }
  ${TrackingClientFragment}
`;

export const updateTrackingClient = `
  mutation updateTrackingClient($id: String!, $input: TrackingClientInput!) {
    updateTrackingClient(id: $id, input: $input) {
      ...TrackingClientFragment
    }
  }
  ${TrackingClientFragment}
`;

export const deleteTrackingClient = `
  mutation deleteTrackingClient($id: String!) {
    deleteTrackingClient(id: $id) {
      ...TrackingClientFragment
    }
  }
  ${TrackingClientFragment}
`;
