import styled from 'styled-components';

export const Tbody = styled.tbody`
  & > tr:first-child {
    border-top: 1px solid #ccc;
  }
`;

export const Tr = styled.tr`
  border-bottom: 1px solid #ccc;
`;

export const Td = styled.td``;

export const Text = styled.div`
  line-height: 2.4;
`;

export const Toolbar = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > div {
    flex: 1;
  }

  & > button {
    margin-right: 5px;
  }
`;
