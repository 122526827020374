import React from 'react';
import styled from 'styled-components';
import { Button, FormGroup, Input } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const Tools = styled.div`
  display: flex;

  & > div {
    flex: 1;
  }
`;

const Buttons = styled.div`
  & > button {
    margin-right: 5px;
  }
`;

const noop = () => {};

const Toolbar = ({
  history = {},
  inputProps = {},
  createButtonText = 'Create',
  createPath,
  refreshButtonText = 'Refresh',
  onRefresh = noop,
  searchInputPlaceHolder = 'Search...'
}) => (
  <Tools>
    <Buttons>
      <Button outline color="primary" onClick={() => history.push(createPath)}>
        {createButtonText}
      </Button>
      <Button outline color="secondary" onClick={onRefresh}>
        {refreshButtonText}
      </Button>
    </Buttons>
    <FormGroup style={{ marginLeft: 5 }}>
      <Input {...inputProps} placeholder={searchInputPlaceHolder} />
    </FormGroup>
  </Tools>
);

export default withRouter(Toolbar);
