import React from 'react';
import TrackingClientForm from '../components/TrackingClientForm';
import { API, graphqlOperation } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';
import uuidv4 from 'uuid/v4';

import Loading from '../components/Loading';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

export default class extends React.Component {
  deleteClient = async id => {
    await API.graphql(graphqlOperation(mutations.deleteTrackingClient, { id }));
    this.props.history.push('/tracking-clients');
  };

  validation = async ({ id, slug }) => {
    if (!slug) return;

    const { data = {} } = await API.graphql(
      graphqlOperation(queries.searchTrackingClients, { slug })
    );

    const [client] = data.searchTrackingClients;

    return [client && client.id !== id && 'slug'].filter(Boolean);
  };

  render() {
    const { match = {} } = this.props;
    const { params: { id } = {} } = match;

    const isUpdate = id !== 'create';

    if (!isUpdate) {
      return (
        <Connect mutation={graphqlOperation(mutations.createTrackingClient)}>
          {({ mutation }) => (
            <TrackingClientForm
              client={{ id: uuidv4() }}
              isUpdate={isUpdate}
              onSubmit={data => mutation(data)}
              onCancel={() => this.props.history.push(`/tracking-clients`)}
              onDelete={this.deleteClient}
              onValidate={this.validation}
            />
          )}
        </Connect>
      );
    }

    return (
      <Connect
        query={graphqlOperation(queries.getTrackingClient, { id })}
        mutation={graphqlOperation(mutations.updateTrackingClient)}
      >
        {({ data: { client }, loading, error, mutation }) => {
          if (error) return <h3>Error</h3>;
          if (loading || !client) return <Loading />;

          return (
            <TrackingClientForm
              client={client}
              isUpdate={isUpdate}
              onSubmit={data => mutation(data)}
              onCancel={() => this.props.history.push(`/tracking-clients`)}
              onDelete={this.deleteClient}
              onValidate={this.validation}
            />
          );
        }}
      </Connect>
    );
  }
}
