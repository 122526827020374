import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';

import { trackingAppUrl } from '../config';
import { formatDate } from '../utils';
import { Tbody, Tr, Td } from './styles';

export default ({ clients = [] }) => (
  <Table borderless size="sm">
    <thead>
      <tr>
        <th>Client</th>
        <th>Query</th>
        <th>CreatedAt</th>
        <th>UpdatedAt</th>
      </tr>
    </thead>
    <Tbody>
      {clients.map(client => (
        <Tr key={client.id}>
          <Td>
            <Link to={`/tracking-clients/${client.id}`}>{client.name}</Link>
          </Td>
          <Td>
            <a
              href={`${trackingAppUrl}?client=${client.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              /?client={client.slug}
            </a>
          </Td>
          <Td>{formatDate(client.createdAt)}</Td>
          <Td>{formatDate(client.updatedAt)}</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);
