import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';

import { formatDate } from '../utils';
import { Tbody, Tr, Td } from './styles';

export const SgLink = ({ id, name }) => <Link to={`/security-groups/${id}`}>{name}</Link>;

export default ({ customers }) => (
  <Table borderless size="sm">
    <thead>
      <tr>
        <th>Customer</th>
        <th>CreatedAt</th>
        <th>UpdatedAt</th>
      </tr>
    </thead>
    <Tbody>
      {customers.map(c => (
        <Tr key={c.id}>
          <Td>
            <SgLink id={c.id} name={c.name} />
          </Td>
          <Td>{formatDate(c.createdAt)}</Td>
          <Td>{formatDate(c.updatedAt)}</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);
