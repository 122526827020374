import React from 'react';
import Select from 'react-select';
import { graphqlOperation } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';

import Loading from '../components/Loading';
import UserForm from '../components/UserForm';
import SearchSecurityGroup from '../components/SearchSecurityGroup';
import { localeOptions, locationOptions, categoryOptions } from '../constants';
import * as queries from '../graphql/queries';

const userAttributes = [
  { name: 'email', label: 'E-Mail' },
  // { Name: 'email_verified', Value: 'true' },
  { name: 'name', label: 'Name' },
  {
    name: 'locale',
    label: 'Locale',
    Component: Select,
    props: { /* defaultValue: localeOptions[0], */ options: localeOptions }
  },
  { name: 'custom:aclKey', label: 'ACL key', Component: SearchSecurityGroup },
  {
    name: 'custom:location',
    label: 'Location',
    Component: Select,
    props: { /*  defaultValue: locationOptions[0], */ options: locationOptions }
  },
  {
    name: 'custom:userCategory',
    label: 'User group',
    Component: Select,
    props: { /*  defaultValue: categoryOptions[0], */ options: categoryOptions }
  }
];

export default class extends React.Component {
  onDeleteUser = () => {
    const { history } = this.props;

    history.push('/users');
  };

  onSubmitUser = ({ Username }) => {
    const { match, history } = this.props;

    if (match.params.username === 'create') {
      history.push(`/users/${Username}`);
    }
  };

  render() {
    const { match = {} } = this.props;
    const { params: { username } = {} } = match;

    if (username === 'create') {
      return (
        <UserForm
          userAttributes={userAttributes}
          onDelete={this.onDeleteUser}
          onSubmit={this.onSubmitUser}
        />
      );
    }

    return (
      <Connect query={graphqlOperation(queries.getUser, { username })}>
        {({ data: { getUser }, loading, error }) => {
          if (error) return <h3>Error</h3>;
          if (loading || !getUser) return <Loading />;
          return (
            <UserForm
              user={getUser}
              userAttributes={userAttributes}
              onDelete={this.onDeleteUser}
              onSubmit={this.onSubmitUser}
            />
          );
        }}
      </Connect>
    );
  }
}
