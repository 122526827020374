import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Col, Input } from 'reactstrap';

const noop = () => { };

export default class AliasCarrier extends React.Component {
  static propTypes = {
    aliasCarrier: PropTypes.bool,
    onAliasCarrierUpdate: PropTypes.func,
    aclKey: PropTypes.string.isRequired
  };
  static defaultProps = {
    aliasCarrier: false,
    onAliasCarrierUpdate: noop,
    aclKey: ''
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { aliasCarrier } = this.props;
    return (
      <FormGroup row key={"aliasCarrier-" + this.props.aclKey} style={{ 'clear': 'both' }}>
        <Label for={"aliasCarrier-" + this.props.aclKey} sm={4}>
          Encode Carrier
        </Label>
        <Col sm={8}>
          <Input
            type="checkbox"
            id={"aliasCarrier-" + this.props.aclKey}
            name={"aliasCarrier-" + this.props.aclKey}
            onChange={this.props.onAliasCarrierUpdate}
            checked={aliasCarrier}
            style={{ 'margin-left': '0' }}
          />
        </Col>
      </FormGroup>
    );
  }
}
