import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import View from './components/View';
import Users from './pages/Users';
import EditUser from './pages/EditUser';
import SecurityGroups from './pages/SecurityGroups';
import EditSecurityGroup from './pages/EditSecurityGroup';
import TrackingClients from './pages/TrackingClients';
import EditTrackingClient from './pages/EditTrackingClient';

export default () => (
  <View>
    <Switch>
      <Route path={`/users`} exact component={Users} />
      <Route path={`/users/:username`} exact component={EditUser} />
      <Route path={`/security-groups`} exact component={SecurityGroups} />
      <Route path={`/security-groups/:customerId`} exact component={EditSecurityGroup} />
      <Route path={`/tracking-clients`} exact component={TrackingClients} />
      <Route path={`/tracking-clients/:id`} exact component={EditTrackingClient} />
      <Redirect to={`/users`} />
    </Switch>
  </View>
);
