import React from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import {
  Collapse,
  Navbar as BsNavbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink as BsNavLink,
  Button
} from 'reactstrap';

const Navbar = styled(BsNavbar)`
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
`;

const NavLink = styled(BsNavLink)`
  color: #343a40;
  margin: 0 10px;
`;

export default class extends React.Component {
  state = {
    isOpen: false
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  signOut = () => {
    Auth.signOut().then(() => this.props.onStateChange('signedOut'));
  };

  render() {
    return (
      <Navbar expand="md">
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to="/users">
                Users
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/security-groups">
                Security Groups
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/tracking-clients">
                Track&Trace
              </NavLink>
            </NavItem>
          </Nav>
          <Button outline color="primary" className="float-right" onClick={this.signOut}>
            Sign Out
          </Button>
        </Collapse>
      </Navbar>
    );
  }
}
