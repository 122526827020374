import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import Loading from './Loading';

export default class DataLoader extends React.Component {
  state = {};

  componentDidMount() {
    this.getData();
  }

  getData = async (props = this.props) => {
    this.setState({
      loading: true
    });

    try {
      const { data = {} } = await API.graphql(graphqlOperation(props.query));
      this.setState({
        data: data[props.dataKey],
        loading: false
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, data } = this.state;
    const { children } = this.props;

    if (loading) {
      return <Loading />;
    }

    return children && children({ data, refresh: this.getData });
  }
}
