const toOptions = (values) => values.map((v) => ({ value: v, label: v }));

const categories = ['Mitarbeiter', 'Kunde', 'Dienstleister'];
export const categoryOptions = toOptions(categories);

const locales = ['de_DE', 'en_EN', 'fr_FR', 'it_IT'];
export const localeOptions = toOptions(locales);

const locations = ['Schaffhausen', 'Basel', 'Buchs', 'Spreitenbach'];
export const locationOptions = toOptions(locations);

const aclGroupTypes = ['agtmNr', 'spediteurNr', 'statbuNr'];
export const aclGroupTypeOptions = toOptions(aclGroupTypes);

const fastDbTypes = ['Schaffhausen', 'Spreitenbach', 'Basel', 'Freight'];
export const fastDbTypeOptions = toOptions(fastDbTypes);
