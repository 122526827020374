export const env =
  process.env.REACT_APP_STAGE || process.env.REACT_APP_ENV || 'development';
export const isProd = env === 'production';
export const isDev = env === 'development';

export const trackingAppUrl = isDev
  ? `https://rhenus-tracking.staging-uniteplus.de`
  : `https://tracking.rhenus.ch`;

const config = {
  development: {
    Auth: {
      identityPoolId: 'eu-central-1:ce417ea2-8b51-4827-acd0-fd389b19d7ea',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_ThM90InzZ',
      userPoolWebClientId: '25tqkupcvidi0hdonga2k1us08',
      mandatorySignIn: false,
    },
    API: {
      aws_appsync_graphqlEndpoint:
        'https://vg36ihbgvnhilpei6ptiumd7qy.appsync-api.eu-central-1.amazonaws.com/graphql',
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    },
    Storage: {
      bucket: 'app-dashboard-manager-staging-assets',
      region: 'eu-central-1',
    },
    Analytics: {
      disabled: true,
      region: 'eu-central-1',
    },
  },
  production: {
    Auth: {
      identityPoolId: 'eu-central-1:fbebe2dd-cc94-4116-89f0-270b7448b849',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_ApuDWa4LJ',
      userPoolWebClientId: '2v8ehd9199e0vqu5hie2js08j',
      mandatorySignIn: true,
    },
    API: {
      aws_appsync_graphqlEndpoint:
        'https://mtyajshugffaxlyj3jxkjhe2lq.appsync-api.eu-central-1.amazonaws.com/graphql',
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    },
    Storage: {
      bucket: 'app-dashboard-manager-prod-assets',
      region: 'eu-central-1',
    },
    Analytics: {
      disabled: true,
      region: 'eu-central-1',
    },
  },
  sst: {
    Auth: {
      identityPoolId: 'eu-central-1:0951630a-3ab2-4bff-aaf8-29e19e40a780',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_lGjFPwZzl',
      userPoolWebClientId: '6284vb1r4la3uhs3gtq7v8s56d',
      mandatorySignIn: false,
    },
    API: {
      aws_appsync_graphqlEndpoint:
        'https://bxba27iigba5loqlslgw3jarba.appsync-api.eu-central-1.amazonaws.com/graphql',
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    },
    Storage: {
      bucket: 'dev-bfe-rhenus-next-storage-bucketbucketf19722a9-nymcoclhm1kg',
      region: 'eu-central-1',
    },
    Analytics: {
      disabled: true,
      region: 'eu-central-1',
    },
  },
};

export default config[env];
