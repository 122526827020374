import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Input } from 'reactstrap';

import { Tr, Td, Tools } from './styles';

import { aclGroupTypeOptions, fastDbTypeOptions } from '../../constants';

const noop = () => {};

export default class ACL extends React.Component {
  static propTypes = {
    newAcl: PropTypes.bool,
    groupName: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ),
    fastDbOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ),
    onAction: PropTypes.func
  };

  static defaultProps = {
    newAcl: false,
    options: aclGroupTypeOptions,
    fastDbOptions: fastDbTypeOptions,
    onAction: noop
  };

  constructor(props) {
    super(props);

    const numType = props.options.find(o => o.value === props.numType);
    const fastDb = props.fastDbOptions.find(o => o.value === props.fastDb);

    this.state = {
      numType,
      fastDb,
      num: props.num,
      desc: props.desc
    };
  }

  resetState = () => {
    this.setState({
      numType: null,
      fastDb: 'Schaffhausen',
      num: '',
      desc: ''
    });
  };

  handleSelect = option => {
    this.setState({
      numType: option
    });
  };
  handleSelectFastDb = option => {
    this.setState({
      fastDb: option
    });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  onAction = type => {
    const { numType = {}, fastDb = {}, num, desc } = this.state;
    const { groupName } = this.props;

    this.props.onAction({
      type,
      payload: {
        groupName,
        numType: numType.value,
        fastDb: fastDb.value,
        num,
        desc
      }
    });

    this.resetState();
  };

  handleAdd = this.onAction.bind(this, 'ACL_ADD');
  handleRemove = this.onAction.bind(this, 'ACL_REMOVE');

  render() {
    const { newAcl, options, fastDbOptions } = this.props;
    const { numType, fastDb = { value: 'Schaffhausen', label: 'Schaffhausen' }, num, desc } = this.state;

    return (
      <Tr>
        <Td>
          <Select
            name="numType"
            options={options}
            value={numType}
            onChange={this.handleSelect}
            isDisabled={!newAcl}
          />
        </Td>
        <Td>
          {(numType && numType.value == 'statbuNr') ? <Select
            name="fastDb"
            options={fastDbOptions}
            value={fastDb}
            onChange={this.handleSelectFastDb}
            isDisabled={!newAcl || (!numType || numType.value != 'statbuNr')}
          /> : null}
        </Td>
        <Td>
          <Input
            name="num"
            type="text"
            value={num || ''}
            onChange={this.handleChange}
            disabled={!newAcl}
          />
        </Td>
        <Td>
          <Input
            name="desc"
            type="text"
            value={desc || ''}
            onChange={this.handleChange}
            disabled={!newAcl}
          />
        </Td>
        <Td>
          <Tools block>
            {newAcl ? (
              <FontAwesomeIcon icon="plus-square" size="lg" onClick={this.handleAdd} />
            ) : (
              <FontAwesomeIcon icon="minus-square" size="lg" onClick={this.handleRemove} />
            )}
          </Tools>
        </Td>
      </Tr>
    );
  }
}
