import React from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { API, graphqlOperation } from 'aws-amplify';
import isEmpty from 'lodash.isempty';

import * as queries from '../graphql/queries';

export default class SearchSecurityGroup extends React.Component {
  state = {
    option: {}
  };
  /*
  I love it when a method has consistent parameters. Unlike this one.
  On Initial Load:
  - If ACL not set: {value: null, ...} -> return: null
  - If ACL set: {value: "PER-001", ...} -> return: null
  On Change:
  - To a valid ACL: {value: {value: "PER-001", label: "PER-001, Personalshop AG (PER-001)"}, ...} -> return: {option: {value: "PER-001", label: "PER-001, Personalshop AG (PER-001)"}}
  - On Clear: {value: {value: null}, ...} -> return: {option: {value: null, label: ''}};
  - On using 'Del': {value: [], ...} -> return: {option: {value: null, label: ''}};
  */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value === null || typeof nextProps.value === 'string') {
      return null;
    }
    if (nextProps.value && nextProps.value.value) {
      return {
        option: nextProps.value
      };
    }
    if (
      (nextProps.value && nextProps.value.value === null) ||
      (Array.isArray(nextProps.value) && nextProps.value.length === 0)
    ) {
      return { option: { value: null, label: '' } };
    }
    return null;
  }

  componentDidMount() {
    this.getData();
  }

  async getData(props = this.props) {
    if (isEmpty(props.value)) {
      return;
    }

    const { data: { searchSecurityGroups = [] } = {} } = await API.graphql(
      graphqlOperation(queries.searchSecurityGroups, {
        query: props.value
      })
    );
    const [sg = {}] = searchSecurityGroups;
    const option = {
      value: sg.aclKey,
      label: `${sg.groupName}, ${sg.customerName} (${sg.aclKey})`
    };

    this.setState({ option });
  }

  loadOptions = inputValue => {
    const query = inputValue || this.props.value;

    if (isEmpty(query)) {
      return;
    }

    return API.graphql(
      graphqlOperation(queries.searchSecurityGroups, {
        query
      })
    ).then(({ data }) => {
      const results = data.searchSecurityGroups || [];

      return results.map(sg => ({
        value: sg.aclKey,
        label: `${sg.groupName}, ${sg.customerName} (${sg.aclKey})`
      }));
    });
  };

  render() {
    return (
      <AsyncSelect
        cacheOptions
        value={this.state.option}
        onChange={this.props.onChange}
        defaultOptions={true}
        loadOptions={this.loadOptions}
        isClearable={this.props.isClearable}
      />
    );
  }
}
