import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import {
  SignIn as AmplifySignIn,
  ForgotPassword,
  VerifyContact,
  withAuthenticator
} from 'aws-amplify-react';
import { createGlobalStyle } from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlusSquare,
  faMinusSquare,
  faPenSquare,
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons';

import Navigation from './components/Navigation';
import Routes from './Routes';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f8f9fa;
  }
`;

library.add(faPlusSquare);
library.add(faMinusSquare);
library.add(faPenSquare);
library.add(faSyncAlt);

class App extends Component {
  render() {
    return (
      <>
        <GlobalStyle />
        <Navigation onStateChange={this.props.onStateChange} />
        <Routes />
      </>
    );
  }
}

class SignIn extends AmplifySignIn {
  checkContact(user) {
    Auth.verifiedContact(user).then(data => {
      // console.log(data);
      this.changeState('signedIn', user);
    });
  }

  showComponent(theme) {
    this.props = {
      ...this.props,
      hide: [{ name: 'SignUp' }]
    };

    return super.showComponent(theme);
  }
}

export default withAuthenticator(App, false, [<SignIn />, <VerifyContact />, <ForgotPassword />]);
