import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import uuidv4 from 'uuid/v4';
import omitBy from 'lodash.omitby';
import isEmpty from 'lodash.isempty';

import SecurityGroupsEditor from '../components/security/SecurityGroupsEditor';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

export default class extends React.Component {
  state = {};

  componentDidMount() {
    this.getData();
  }

  async getData(props = this.props) {
    const { data = {} } = await API.graphql(
      graphqlOperation(queries.listSecurityGroups, {
        customerId: props.match.params.customerId
      })
    );

    const { listSecurityGroups = [], getCustomer = {} } = data;
    const { id: customerId, name: customerName } = getCustomer || {};
    const groups = listSecurityGroups.map(({ groupName, aclKey, aliasCarrier, customergroupLabel, showDeliveryDelayColumn, enableExport, exportInterval=15, enableCharts, relations }) => ({
      groupName,
      aclKey,
      aliasCarrier,
      customergroupLabel,
      showDeliveryDelayColumn,
      enableExport,
      exportInterval,
      enableCharts,
      relations
    }));

    this.setState({
      customerId,
      customerName,
      groups
    });
  }

  saveSecurityGroups = async ({
    customerId = uuidv4(),
    customerName,
    groups = [],
    removed = []
  }) => {
    const updateGroups = groups.map(group => ({
      aclKey: group.aclKey,
      customerId,
      customerName,
      groupName: group.groupName,
      aliasCarrier: group.aliasCarrier,
      customergroupLabel: group.customergroupLabel,
      showDeliveryDelayColumn: group.showDeliveryDelayColumn,
      enableExport: group.enableExport,
      exportInterval: group.exportInterval,
      enableCharts: group.enableCharts,
      relations: group.relations.map(rel => omitBy(rel, isEmpty))
    }));
    const deleteGroups = removed.map(group => ({
      customerId,
      aclKey: group.aclKey
    }));

    // console.log('saveSecurityGroups', { updateGroups, deleteGroups });

    try {
      if (updateGroups.length > 0) {
        const operation = graphqlOperation(mutations.updateSecurityGroups, {
          groups: updateGroups
        });

        await API.graphql(operation);
      }

      if (deleteGroups.length > 0) {
        const operation = graphqlOperation(mutations.deleteSecurityGroups, {
          groups: deleteGroups
        });

        await API.graphql(operation);
      }

      this.setState({
        groups: updateGroups,
        customerId,
        customerName
      });

      const { match, history } = this.props;
      if (match.params.customerId === 'create') {
        history.push(`/security-groups/${customerId}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    return (
      <SecurityGroupsEditor
        {...this.state}
        onSave={this.saveSecurityGroups}
        timestamp={new Date().getTime()}
      />
    );
  }
}
