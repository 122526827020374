import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import Select from 'react-select';

const noop = () => { };

export default class ExportInterval extends React.Component {
  static propTypes = {
    exportInterval: PropTypes.number,
    onExportIntervalUpdate: PropTypes.func,
    aclKey: PropTypes.string.isRequired,
    enableExport: PropTypes.bool
  };
  static defaultProps = {
    exportInterval: 1440,
    onExportIntervalUpdate: noop,
    aclKey: '',
    enableExport: false
  };

  constructor(props) {
    super(props);
  }

  options = [
    { value: 15, label: '15 Minutes' },
    { value: 30, label: '30 Minutes' },
    { value: 45, label: '45 Minutes' },
    { value: 60, label: '1 Hour' },
    { value: 120, label: '2 Hours' },
    { value: 240, label: '4 Hours' },
    { value: 480, label: '8 Hours' },
    { value: 720, label: '12 Hours' },
    { value: 1440, label: '24 Hours' },
  ]

  render() {
    if (this.props.enableExport && !this.props.exportInterval) {
      this.props.onExportIntervalUpdate({ value: 1440 });
    }
    return (
      <FormGroup row key={"exportInterval-" + this.props.aclKey} style={{ 'clear': 'both' }}>
        <Label for={"exportInterval-" + this.props.aclKey} sm={4}>
          Export Interval
        </Label>
        <Col sm={8}>
          <Select
            name={"exportInterval-" + this.props.aclKey}
            options={this.options}
            defaultValue={this.options.find(element => element.value === (this.props.exportInterval || 1440))}
            onChange={this.props.onExportIntervalUpdate}
            isDisabled={!this.props.enableExport}
          />

        </Col>
      </FormGroup>
    );
  }
}
