import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Button, FormGroup, Input } from 'reactstrap';
import matchSorter from 'match-sorter';
import Downshift from 'downshift';

import { debounce } from '../utils';
import Loading from '../components/Loading';
import CustomerList from '../components/CustomerList';
import * as queries from '../graphql/queries';
import { Box } from './styles';

export default class extends React.Component {
  state = {};

  componentDidMount() {
    this.getData();
  }

  async getData(props = this.props) {
    this.setState({
      loading: true
    });

    try {
      const { data = {} } = await API.graphql(graphqlOperation(queries.listCustomers));
      const { listCustomers } = data;

      this.setState({
        items: listCustomers.items,
        list: listCustomers.items,
        loading: false
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  search = debounce(inputValue => {
    this.setState(
      {
        inputValue
      },
      this.updateList
    );
  }, 500);

  updateList = () => {
    const { items = [], inputValue } = this.state;

    let list = inputValue
      ? matchSorter(items, inputValue, {
          keys: ['name']
        })
      : items;

    this.setState({ list });
  };

  render() {
    const { list = [], loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <Downshift onStateChange={this.onStateChange}>
          {({ getInputProps }) => (
            <div>
              <Box>
                <div>
                  <Button
                    outline
                    color="primary"
                    onClick={() => this.props.history.push('/security-groups/create')}
                  >
                    Create Security Group
                  </Button>
                  <Button
                    outline
                    color="secondary"
                    style={{ marginLeft: 5 }}
                    onClick={() => this.getData()}
                  >
                    Refresh
                  </Button>
                </div>
                <FormGroup style={{ marginLeft: 5 }}>
                  <Input
                    {...getInputProps({
                      onChange: event => {
                        const value = event.target.value;
                        this.search(value);
                      }
                    })}
                    placeholder="Search for customer..."
                  />
                </FormGroup>
              </Box>
              <CustomerList customers={list} />
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}
