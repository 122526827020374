import React from 'react';
import { FormGroup, FormFeedback, Input } from 'reactstrap';

const noop = () => {};

export default ({
  value = '',
  disabled = false,
  invalid = false,
  feedback = 'Invalid customer name!',
  onChange = noop
}) => {
  if (disabled) {
    return <h5>{value}</h5>;
  }

  return (
    <FormGroup>
      <Input invalid={invalid} value={value} placeholder="Customer name" onChange={onChange} />
      <FormFeedback>{feedback}</FormFeedback>
    </FormGroup>
  );
};
